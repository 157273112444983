// Declan created service
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tap, map } from 'rxjs/internal/operators';
import { User } from '../interfaces/user';
import { JwtHelperService } from '@auth0/angular-jwt';
import { of, Observable, Subject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { LoggedInOutService } from '../../shared/services/logged-in-out.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class JwtService {

  public loggedIn: boolean = false;
  public userDetails: any = {'data' : {'first_name' : '', 'last_name' : '', 'company' : '', 'country' : '', 'address1' : '', 'address2' : '', 'town' : '', 'county' : '',
                                       'zip' : '', 'email' : '', 'phone' : '', 'account_allowed' : false}
                                      };
  public customerId: number = 0;

  public formDetailsDelay: Subject<boolean> = new Subject<boolean>();

  constructor(private _http: HttpClient, public _jwtHelper: JwtHelperService, private _logOutIn: LoggedInOutService, private router: Router ) { }

  // zendcode.localhost/jwt/verifytoken
  login(user: User) {
    return this._http.post(`${environment.env_api}/users/authenticate`, user).pipe(tap(
                data => {
                    localStorage.setItem('access_token', data['access_token']);
                    this.loadCustomerDetails().subscribe(result => {
                      this.userDetails = result;
                      this.setLoggedInOutValues();
                    },
                    err => {
                      // This error will be triggered if the api call to fetch customer data in loadCustomerDetails() method returns any 404 etc
                      // If so remove any token which will set the user back into a logged out state
                      localStorage.removeItem('access_token');
                      this.reloadCustomerdetails();
                    });
                },
                err => {
                    console.log("Error in service triggered");
                    // This error will be triggered if user goes to log in and authentication api fails
                    // If for example this happens when user tries to log in while already logs in and api fails then remove token and reload
                    localStorage.removeItem('access_token');
                    this.reloadCustomerdetails();
                }
                // can include this if I want to do something when everything runs successfully
                //() => console.log('Job done')
              ));
  }

  logout() {
    localStorage.removeItem('access_token');
    this.reloadCustomerdetails();
  }

  // Verification response returns a string on successfull response and a 401 error if not authorised
  // I needed to write it this way with toPromise because this is the only way I could get it to work on both successfull requests and failed requests
  // Also return type needed to be Promise since I was using toPromise
  // Why this way I'm not entirly sure and need to learn more about this side of Angular
  // Any way this method returns true or false to the AuthGuard protecting all the account routes

  // toPromise is about to become deprecated. Look up toPromise vs subscribing to observable
  // newer version is validateToken() below this method
  validateTokenOldWay(isLogin: boolean): Promise<boolean> {
      return this._http.get(`${environment.env_api}/verification`, {responseType: 'text'}).toPromise().then(() => { 
        
          if (isLogin == true) {
            this.router.navigateByUrl('/account/dashboard').then();
            return false;
          }
          return true;

      })
      .catch((error) => {
      
          if (isLogin == false) {
              this.router.navigateByUrl('/account/login').then();
              return false;
          }
          return true;

      });
  }

  // Verification response returns a string on successfull response and a 401 error if not authorised
  // Only handle successfull requests here
  // If api fails the error will be handled in the AuthGuard where this method is called
  validateToken(isLogin: boolean): Observable<boolean> {
    return this._http.get(`${environment.env_api}/verification`, {responseType: 'text'}).pipe(map(
      result => {
        if (isLogin == true) {
          this.router.navigateByUrl('/account/dashboard').then();
          return false;
        }
        return true;
      }
    ))
}

  get loggedInStatus(): boolean{
    if (this._jwtHelper.decodeToken(localStorage.getItem('access_token'))) {
      this.loggedIn = true;
    } else {
      this.loggedIn = false;
    }
    return this.loggedIn;
  }

  get customerIdfromToken(): number {
    if (this.loggedInStatus) {
      this.customerId = parseInt(this._jwtHelper.decodeToken(localStorage.getItem('access_token'))['unique_name']);
    } else {
      this.customerId = 0;
    }
    return this.customerId;
  }

  get customerDetails(): Object{
    if (this.loggedInStatus == true) {
      return this.userDetails;
    } else {
      return {'data' : {'first_name' : '', 'last_name' : '', 'company' : '', 'country' : '',
              'address1' : '', 'address2' : '', 'town' : '', 'county' : '',
              'zip' : '', 'email' : '', 'phone' : '', 'account_allowed' : false}
             }
    }
  }

  loadCustomerDetails() {

    if (this.loggedInStatus) {
      this.customerId = parseInt(this._jwtHelper.decodeToken(localStorage.getItem('access_token'))['unique_name']);

      return this._http.get<any>(`${environment.env_api}/api/customer/${this.customerId}`).pipe(
        map(cust => {
          return {
            data: {
              first_name: cust.customers_firstname,
              last_name: cust.customers_lastname,
              company: cust.company,
              country: 'Ireland',
              address1: cust.address,
              address2: '',
              town: cust.suburb,
              county: cust.city,
              zip: cust.post_code,
              email: cust.customers_email_address,
              phone: cust.customers_telephone,
              account_allowed: cust.purchased_on_account
            }
          }
        })
      );
    } else {

      // When loadCustomerDetails() is been called and loggedInStatus is false always set customerId back to 0 as details are getting set back to empty strings
      this.customerId = 0;

      return of({'data' : {'first_name' : '', 'last_name' : '', 'company' : '', 'country' : '',
              'address1' : '', 'address2' : '', 'town' : '', 'county' : '',
              'zip' : '', 'email' : '', 'phone' : '', 'account_allowed' : false}
             });

    }
    
  }

  reloadCustomerdetails() {
    this.loadCustomerDetails().subscribe(result => {
      this.userDetails = result;
      // Change the value of this Subject so the components that contains the forms for a users info and billing form customer details will populate
      // with customers info if browser is physacaly refreshed as these various components are subscribing to the event change so they can set the proper values 
      // in the form fields. These values are the response of loadCustomerDetails() api call above
      this.formDetailsDelay.next(true);
      this.setLoggedInOutValues();
    },
    err => {
      // If this error is triggered then it means that api call to retieve customer details has failed
      // Remove the token which means that loggedInStatus will be false so customerDetails will return blank userDetails
      // Also reset customerId back to 0 as we don't want any referenceof any previous user that was once logged in before api request failed
      localStorage.removeItem('access_token');
      this.customerId = 0;
      this.setLoggedInOutValues();
    });
  }

  // When jwt service is used to log in or log out, every time call this method which triggeres one of two methods below
  // The logic is set out in a dedicated services which will decide what menu, products, categories etc to show wheathe user logged in or out
  setLoggedInOutValues() {
    if (this.loggedInStatus) {
      this._logOutIn.setLoggedInValues(this.customerId);
    } else {
      this._logOutIn.setLoggedOutValues();
    }
  }

}
