import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { CartService } from '../../services/cart.service';
import { MinimumQuantityService } from '../../services/minimum-quantity.service';
import { Product, ProductAttribute } from '../../interfaces/product';
import { WishlistService } from '../../services/wishlist.service';
import { CompareService } from '../../services/compare.service';
import { QuickviewService } from '../../services/quickview.service';
import { RootService } from '../../services/root.service';
import { CurrencyService } from '../../services/currency.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-product-card',
    templateUrl: './product-card.component.html',
    styleUrls: ['./product-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductCardComponent implements OnInit, OnDestroy, OnChanges {
    private destroy$: Subject<void> = new Subject();

    @Input() product: Product;
    @Input() layout: 'grid-sm'|'grid-nl'|'grid-lg'|'list'|'horizontal'|null = null;

    addingToCart = false;
    addingToWishlist = false;
    addingToCompare = false;
    showingQuickview = false;
    featuredAttributes: ProductAttribute[] = [];

    //Declan 02/03/2021
    minimumQuantity: number = 1;

    constructor(
        private cd: ChangeDetectorRef,
        public root: RootService,
        public cart: CartService,
        public wishlist: WishlistService,
        public compare: CompareService,
        public quickview: QuickviewService,
        public currency: CurrencyService,
        private minimumtest: MinimumQuantityService
    ) { }

    ngOnInit(): void {
        //Declan 02/03/2021
        this.minimumtest.singleProductView$.subscribe(val => {
            this.minimumQuantity = this.minimumtest.checkMinimumQuantity(this.product.id);
            // When this subscribe is triggerd from a component outside this component, 
            // the minimumQuantity property value in view does not update
            // https://stackoverflow.com/questions/41364386/whats-the-difference-between-markforcheck-and-detectchanges
            // Tell angular to check this component for updates 
            // Using this.cd.markForCheck(); instead of this.cd.detectChanges(); as template code is using below
            // Need to read more into this angular concept
            this.cd.markForCheck();
        });

        this.currency.changes$.pipe(takeUntil(this.destroy$)).subscribe(() => {
            this.cd.markForCheck();
        });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if ('product' in changes) {
            this.featuredAttributes = !this.product ? [] : this.product.attributes.filter(x => x.featured);
        }
    }

    addToCart(): void {
        if (this.addingToCart) {
            return;
        }

        // Declan 01/03/2021 added checkMinimumQuantity property to this.cart.add(this.product, 1) <== ORIGINAL
        this.addingToCart = true;
        this.cart.add(this.product, this.minimumtest.checkMinimumQuantity(this.product.id)).subscribe({
            complete: () => {
                this.addingToCart = false;
                // Declan 03/03/2021
                // When product added trigger behaviour subject so subscribe will run and recalculate minimum
                this.minimumtest.recalculateQuantitySingleProductPage();
                this.cd.markForCheck();
            }
        });
    }

    addToWishlist(): void {
        if (this.addingToWishlist) {
            return;
        }

        this.addingToWishlist = true;
        this.wishlist.add(this.product).subscribe({
            complete: () => {
                this.addingToWishlist = false;
                this.cd.markForCheck();
            }
        });
    }

    addToCompare(): void {
        if (this.addingToCompare) {
            return;
        }

        this.addingToCompare = true;
        this.compare.add(this.product).subscribe({
            complete: () => {
                this.addingToCompare = false;
                this.cd.markForCheck();
            }
        });
    }

    showQuickview(): void {
        if (this.showingQuickview) {
            return;
        }

        this.showingQuickview = true;
        this.quickview.show(this.product).subscribe({
            complete: () => {
                this.showingQuickview = false;
                this.cd.markForCheck();
            }
        });
    }
}
