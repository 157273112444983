import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class MinimumQuantityService {

    private minimumProductsList: Array<Object> = [{ 'product_id' : 783,  'value' : 10, 'currentCount' : 0 }];

    public singleProductView$: BehaviorSubject<boolean> = new BehaviorSubject(false);

    constructor() { }

    recalculateQuantitySingleProductPage() {
        this.singleProductView$.next(true);
    }

    //Declan 02/03/2021
    checkMinimumQuantity(product_id, cartValidate = false): number {
        var record = this.minimumProductsList.find(obj => obj['product_id'] == product_id);
        
        if (record) {
            if ((record['currentCount'] >= record['value']) && !cartValidate) {
                return 1;
            } else {
                return record['value'];
            }
        } else {
            return 1;
        }
    }

    //Declan 02/03/2021
    updateMinimumQuantityCheckValue(product_id, current_count, remove = false): void {
        var record = this.minimumProductsList.find(p => p['product_id'] == product_id);
        if (record) {
            record['currentCount'] = current_count;
        }
        if (record && remove) {
            record['currentCount'] = 0;
        }
    }

    loadMinimumQuantityCount(items) : void {
        items.forEach(obj => {
            this.updateMinimumQuantityCheckValue(obj.product.id, obj.quantity);
        });
    }

    validateUserInput(product_id, current_user_value, bool) {
        // If value contains a decimal, then it is not valid
        if (current_user_value.toString().indexOf(".") != -1) {
            return false;
        }

        // Get minimum product quantity for product and check
        var minimumQuantity = this.checkMinimumQuantity(product_id, bool);
        return (current_user_value < minimumQuantity) ? false : true;
    }

}