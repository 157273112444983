import { Component, EventEmitter, Output, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { JwtService } from '../../../../shared/services/jwt.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-account-menu',
    templateUrl: './account-menu.component.html',
    styleUrls: ['./account-menu.component.scss']
})
export class AccountMenuComponent implements OnInit {
    @Output() closeMenu: EventEmitter<void> = new EventEmitter<void>();

    loginForm: FormGroup;
    submitted: boolean = false;
    showError: boolean = false;

    constructor(public jwt: JwtService, private fb: FormBuilder, private router: Router) { }

    ngOnInit(): void {
        this.loginForm = this.fb.group({
            Customers_email_address: ['', [Validators.required, Validators.email]],
            password: ['', [Validators.required]]
        });
    }

    // convenience getter for easy access to form fields
    get fv() { return this.loginForm.controls; }

    onSubmit() {
        this.submitted = true;
    
        // stop here if form is invalid
        if (this.loginForm.invalid) {
            return;
        }

        this.jwt.login(this.loginForm.value).subscribe(
            result => {
                this.showError = false;
                this.closeMenu.emit();
                this.router.navigateByUrl('/account/dashboard').then();
            },
            err => {
                console.log("Log in error");
                this.showError = true;
            }
        );
    }
}
