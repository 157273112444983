<div class="account-menu">
    <form *ngIf="!jwt.loggedInStatus" [formGroup]="loginForm" (ngSubmit)="onSubmit()" class="account-menu__form">
        <div class="account-menu__form-title">Log In to Your Account</div>
        <div class="form-group">
            <label for="header-signin-email" class="sr-only">Email address</label>
            <input id="header-signin-email" type="email" formControlName="Customers_email_address" class="form-control form-control-sm" placeholder="Email address" [ngClass]="{ 'is-invalid': submitted && fv.Customers_email_address.errors }">
            <div *ngIf="submitted && fv.Customers_email_address.errors" class="invalid-feedback">
                <div *ngIf="fv.Customers_email_address.errors.required">Email is required</div>
                <div *ngIf="fv.Customers_email_address.errors.email">Email must be a valid email address</div>
            </div>
        </div>
        <div class="form-group">
            <label for="header-signin-password" class="sr-only">Password</label>
            <div class="account-menu__form-forgot">
                <input id="header-signin-password" type="password" formControlName="password" class="form-control form-control-sm" placeholder="Password" [ngClass]="{ 'is-invalid': submitted && fv.password.errors }">
                <div *ngIf="submitted && fv.password.errors" class="invalid-feedback">
                    <div *ngIf="fv.password.errors.required">Password is required</div>
                </div>
                <a [routerLink]="'/account/forgot-password'" (click)="closeMenu.emit()" class="account-menu__form-forgot-link">Forgot?</a>
            </div>
        </div>
        <!-- Declan add alert -->
        <div [class.show]="showError" [class.hide]="!showError" class="alert alert-danger" role="alert">
            Invalid login
        </div>
        <div class="form-group account-menu__form-button">
            <button type="submit" class="btn btn-primary btn-sm">Login</button>
        </div>
        <!-- <div class="account-menu__form-link"><a routerLink="/account/login" (click)="closeMenu.emit()">Create An Account</a></div> -->
    </form>
    <!-- <div class="account-menu__divider"></div> -->
    <!-- Declan adding a wrap around so I can display whats needed based on jwt.loggedInStatus -->
    <div *ngIf="jwt.loggedInStatus">
        <a routerLink="/account/dashboard" class="account-menu__user" (click)="closeMenu.emit()">
            <div class="account-menu__user-avatar">
                <img src="assets/images/avatars/avatar-3.jpg" alt="">
            </div>
            <div class="account-menu__user-info">
                <div class="account-menu__user-name">{{ jwt.customerDetails['data']['first_name'] }} {{ jwt.customerDetails['data']['last_name'] }}</div>
                <div class="account-menu__user-email">{{ jwt.customerDetails['data']['email'] }}</div>
            </div>
        </a>
        <div class="account-menu__divider"></div>
        <ul class="account-menu__links">
            <li><a routerLink="/account/profile" (click)="closeMenu.emit()">Edit Profile</a></li>
            <li><a routerLink="/account/orders" (click)="closeMenu.emit()">Order History</a></li>
            <!-- <li><a routerLink="/account/addresses" (click)="closeMenu.emit()">Addresses</a></li>
            <li><a routerLink="/account/password" (click)="closeMenu.emit()">Password</a></li> -->
        </ul>
        <div class="account-menu__divider"></div>
        <ul class="account-menu__links">
            <!-- Declan logout and close menu Example of how to execute two functions at the same time on one click -->
            <li><a routerLink="/" (click)="closeMenu.emit(); jwt.logout();">Logout</a></li>
        </ul>
    </div>
</div>
