import { Component, EventEmitter, Output } from '@angular/core';
import { CartService } from '../../../../shared/services/cart.service';
import { CartItem } from '../../../../shared/interfaces/cart-item';
import { RootService } from '../../../../shared/services/root.service';
import { MinimumQuantityService } from '../../../../shared/services/minimum-quantity.service';

@Component({
    selector: 'app-header-dropcart',
    templateUrl: './dropcart.component.html',
    styleUrls: ['./dropcart.component.scss']
})
export class DropcartComponent {
    removedItems: CartItem[] = [];

    @Output() closeMenu: EventEmitter<void> = new EventEmitter<void>();

    constructor(
        public cart: CartService,
        public root: RootService,
        private minimumtest: MinimumQuantityService
    ) { }

    remove(item: CartItem): void {
        if (this.removedItems.includes(item)) {
            return;
        }

        this.removedItems.push(item);
        this.cart.remove(item).subscribe({complete: () => {
                this.removedItems = this.removedItems.filter(eachItem => eachItem !== item);
                // Declan 03/03/2021 update behaviour subject so subscribe on product page 
                // will run so minimum quantity can be recalculated
                this.minimumtest.recalculateQuantitySingleProductPage();
            }
        });
    }
}
