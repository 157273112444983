import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Product } from '../interfaces/product';
import { CartItem } from '../interfaces/cart-item';
import { BehaviorSubject, Observable, Subject, timer, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
// Declan import jwt service so I can pass in customerId and name when placing order
import { JwtService } from './jwt.service';
import { environment } from '../../../environments/environment';
import { MinimumQuantityService } from './minimum-quantity.service';

interface CartTotal {
    title: string;
    price: number;
    type: 'shipping'|'fee'|'tax'|'discount'|'other';
}

// Declan added discount to Cartdata interface below and every where in cart service where it references discount is all newly 
// added code as template did not handle a discount value
interface CartData {
    items: CartItem[];
    quantity: number;
    subtotal: number;
    isDiscount: boolean;
    discount: number;
    shipping: number;
    totals: CartTotal[];
    total: number;
}

@Injectable({
    providedIn: 'root'
})
export class CartService {

    // Declan added coupon_code property so I can keep track of the value of the coupon code
    public hasCouponBeenApplied: boolean = false;
    public coupon_code: string = '';
    public validcoupon: boolean = false;
    public invalidCoupon: boolean = false;
    public couponMsg: string = 'Invalid Coupon';
    public discountTotal:number = 0;
    public discountUnitprice: Array<Object> = [];

    private data: CartData = {
        items: [],
        quantity: 0,
        subtotal: 0,
        isDiscount: false,
        discount: 0,
        shipping: 0,
        totals: [],
        total: 0
    };

    private itemsSubject$: BehaviorSubject<CartItem[]> = new BehaviorSubject(this.data.items);
    private quantitySubject$: BehaviorSubject<number> = new BehaviorSubject(this.data.quantity);
    private subtotalSubject$: BehaviorSubject<number> = new BehaviorSubject(this.data.subtotal);
    private totalsSubject$: BehaviorSubject<CartTotal[]> = new BehaviorSubject(this.data.totals);
    private totalSubject$: BehaviorSubject<number> = new BehaviorSubject(this.data.total);
    private onAddingSubject$: Subject<Product> = new Subject();

    get items(): ReadonlyArray<CartItem> {
        return this.data.items;
    }

    get quantity(): number {
        return this.data.quantity;
    }

    readonly items$: Observable<CartItem[]> = this.itemsSubject$.asObservable();
    readonly quantity$: Observable<number> = this.quantitySubject$.asObservable();
    readonly subtotal$: Observable<number> = this.subtotalSubject$.asObservable();
    readonly totals$: Observable<CartTotal[]> = this.totalsSubject$.asObservable();
    readonly total$: Observable<number> = this.totalSubject$.asObservable();

    readonly onAdding$: Observable<Product> = this.onAddingSubject$.asObservable();

    constructor(
        @Inject(PLATFORM_ID)
        private platformId: any,
        // Declan add http client and Jwt service to send order over to dot net api
        private http: HttpClient,
        private jwt: JwtService,
        private minimumtest: MinimumQuantityService
    ) {
        if (isPlatformBrowser(this.platformId)) {
            this.load();
            this.calc();
        }
    }

    add(product: Product, quantity: number, options: {name: string; value: string}[] = []): Observable<CartItem> {
        // timer only for demo
        return timer(1000).pipe(map(() => {
            this.onAddingSubject$.next(product);

            let item = this.items.find(eachItem => {
                if (eachItem.product.id !== product.id || eachItem.options.length !== options.length) {
                    return false;
                }

                if (eachItem.options.length) {
                    for (const option of options) {
                        if (!eachItem.options.find(itemOption => itemOption.name === option.name && itemOption.value === option.value)) {
                            return false;
                        }
                    }
                }

                return true;
            });

            if (item) {
                item.quantity += quantity;
                //Declan 02/03/2021 minimum quantity feature update realtime values
                this.minimumtest.updateMinimumQuantityCheckValue(product.id, item.quantity);
            } else {
                item = {product, quantity, options};
                //Declan 02/03/2021 minimum quantity feature update realtime values
                this.minimumtest.updateMinimumQuantityCheckValue(product.id, quantity);
                this.data.items.push(item);
            }

            this.save();
            this.calc();
            
            // Redo discount calculations this.calc() is called within this method so ne need to call it like it was originally called
            // Need to subscribe twice as setDiscount returns two observables
            // No need to do any error handling as this is done withing the method for values within this cart service

            // Declan 01/09/2021
            // Comment out discount check everytime cart is updated. We are no longer using a discount coupon option but I am leaving all the discount related code in build
            // if we ever want to re-introduce discount option to shop
            // As this method below is no longer been called, we will go back to calling this.calc() above
            // this.setDiscount(this.coupon_code).subscribe(timer => {
            //     timer.subscribe();
            // });

            return item;
        }));
    }

    update(updates: {item: CartItem, quantity: number}[]): Observable<void> {
        // timer only for demo
        return timer(1000).pipe(map(() => {
            updates.forEach(update => {
                const item = this.items.find(eachItem => eachItem === update.item);

                if (item) {
                    item.quantity = update.quantity;
                    //Declan 02/03/2021 minimum quantity feature update realtime values
                    this.minimumtest.updateMinimumQuantityCheckValue(update.item.product.id, update.quantity);
                }
            });

            this.save();
            this.calc();

            // Redo discount calculations this.calc() is called within this method so ne need to call it like it was originally called
            // Need to subscribe twice as setDiscount returns two observables
            // No need to do any error handling as this is done withing the method for values within this cart service

            // Declan 01/09/2021
            // Comment out discount check everytime cart is updated. We are no longer using a discount coupon option but I am leaving all the discount related code in build
            // if we ever want to re-introduce discount option to shop
            // As this method below is no longer been called, we will go back to calling this.calc() above
            // this.setDiscount(this.coupon_code).subscribe(timer => {
            //     timer.subscribe();
            // });
        }));
    }

    remove(item: CartItem): Observable<void> {
        // timer only for demo
        return timer(1000).pipe(map(() => {
            //Declan 02/03/2021 minimum quantity feature update realtime values
            this.minimumtest.updateMinimumQuantityCheckValue(item.product.id, item.quantity, true);

            this.data.items = this.data.items.filter(eachItem => eachItem !== item);

            this.save();
            this.calc();

            // Redo discount calculations this.calc() is called within this method so ne need to call it like it was originally called
            // Need to subscribe twice as setDiscount returns two observables
            // No need to do any error handling as this is done withing the method for values within this cart service

            // Declan 01/09/2021
            // Comment out discount check everytime cart is updated. We are no longer using a discount coupon option but I am leaving all the discount related code in build
            // if we ever want to re-introduce discount option to shop
            // As this method below is no longer been called, we will go back to calling this.calc() above
            // this.setDiscount(this.coupon_code).subscribe(timer => {
            //     timer.subscribe();
            // });
        }));
    }

    // At another stage of this project I may remove all this newely add custom code in these 4 functions/methods below into a custom ics cart service
    // This way all my custom code to make this project template work with our shop needs for ICS can sit sepperatly and only minor changes will be made to the existing
    // cart service code and any other original service code for this angular project template
    // This method return an Observable<Observable> Look at the way I am subscribing to it in component
    setDiscount(code: string): Observable<any> {
        // timer only for demo but I actually like the idea of a timer delay
        //11/11/2020
        // Original timer delay was 1000. I set to 1 to virtually get rid of delay without changing code
        return timer(1).pipe(map(() => {
            
            // Also not that console.log works in a service (didn't know that ha)
            return this.http.post(`${environment.env_api}/coupon/validate`, {"coupon_code" : code}).pipe(tap(
                data => {
                    console.log("Success");
                    this.coupon_code = code;
                    this.calculateDiscount(data);
                },
                err => {
                    console.log("Fail");
                    this.coupon_code = '';
                    this.data.isDiscount = false;
                    this.data.discount = 0;
                    this.discountTotal = 0;
                    this.discountUnitprice = [];
                    this.validcoupon = false;
                    this.invalidCoupon = true;
                    this.couponMsg = 'Invalid Coupon';
                    this.calc();
                }
              ));
            
        }));
    }

    // If this method is triggered it means that the coupon code entered is valid
    calculateDiscount(data) {

        // Reset discount value and recalculate
        this.data.discount = 0;
        this.discountTotal = 0;
        this.discountUnitprice = [];

        this.data.items.forEach(prod => {
            // check to see if eligible_products returned from coupon api contains any product_id's currently inside shopping cart
            if (data['eligible_products'].includes(prod.product.id)) {
                this.data.discount += (data['amount'] * prod.quantity);
                this.discountUnitprice.push({product_id: prod.product.id, discount_amount: data['amount'], unit_quantity: prod.quantity});
            }
        });

        // if discount has a value greater that 0 then a product in the cart is associated with the elegibale products returned from coupon api
        if (this.data.discount > 0) {
            this.data.isDiscount = true;
            this.discountTotal = this.data.discount;
            this.validcoupon = true;
            this.invalidCoupon = false;
            this.couponMsg = 'Valid Coupon';
            this.calc();
        } else {
            // Reset values when method is called when proceding to checkout
            // we need to recalculate incase user has removed products from cart that are associated with coupon
            // This way user will proceed to checkout and complete purchase with non coupon related products in shopping cart
            // any discount that was once applied has been removed
            // Dont reset coupon code if api was successfull but no coupon related products in cart. 
            // They may add in again before proceding to checkout and if its a discount related product the discount should be automaticly applied
            // as they have previously entered a valid coupon
            // Everytime they enter a coupon we want to lock existing coupon they entered in place
            this.data.isDiscount = false;
            this.data.discount = 0;
            this.discountTotal = 0;
            this.discountUnitprice = [];
            this.validcoupon = false;
            this.invalidCoupon = true;
            this.couponMsg = 'No Items for Valid Coupon';
            this.calc();
        }
        
    }

    // Declan transform this.data within cart service which is of type array and convert to observable so I can start mapping out structure for Ash api
    // This is a good example of using multiple maps to get what I want. As you can see for cart_Items I am performing another map to map out the array structure
    // that I need for cart_Items
    // orders_Totals is also returning an array but as there is so much difference and variation between the data I get from the cart object and the data
    // i need to send back to ash api for our shop database it was easier to seperate this task into a different function
    // It makes for cleaner code
    // This is a great example of how you can take one api structure and completely transform it so the data been sent accross will match the recieving api structure
    submitOrder(customerOrderForm, paymentMethod): Observable<any> {
        return of(this.data).pipe(map(cart => {
            return {
                customers_name : customerOrderForm.first_name + ' ' + customerOrderForm.last_name,
                customers_id : this.jwt.customerId,
                company: customerOrderForm.company,
                // address: customerOrderForm.unit + ' ' + customerOrderForm.street,
                address: customerOrderForm.unit,
                suburb: customerOrderForm.suburb,
                city: customerOrderForm.city,
                country: customerOrderForm.country,
                telephone: customerOrderForm.telephone,
                email: customerOrderForm.email,
                payment_method: paymentMethod,
                comments: customerOrderForm.comments,
                po_number: customerOrderForm.po_number,
                customers_postcode: customerOrderForm.customers_postcode,
                delivery_name: customerOrderForm.first_name + ' ' + customerOrderForm.last_name,
                delivery_company: customerOrderForm.company,
                // delivery_street_address: customerOrderForm.unit + ' ' + customerOrderForm.street,
                delivery_street_address: customerOrderForm.unit,
                delivery_suburb: customerOrderForm.suburb,
                delivery_city: customerOrderForm.city,
                delivery_postcode: customerOrderForm.customers_postcode,
                delivery_state: customerOrderForm.city,
                delivery_country: customerOrderForm.country,
                billing_name: customerOrderForm.first_name + ' ' + customerOrderForm.last_name,
                billing_company: customerOrderForm.company,
                // billing_street_address: customerOrderForm.unit + ' ' + customerOrderForm.street,
                billing_street_address: customerOrderForm.unit,
                billing_suburb: customerOrderForm.suburb,
                billing_city: customerOrderForm.city,
                billing_postcode: customerOrderForm.customers_postcode,
                billing_state: customerOrderForm.city,
                billing_country: customerOrderForm.country,
                orders_Totals : this.listCosts(cart.totals, cart.subtotal, cart.total),
                cart_Items : cart.items.map(item => {
                    return {
                        products_id : item.product.id,
                        products_name : item.product.name, 
                        products_price : item.product.price, 
                        products_quantity : item.quantity
                    }
                })
            };
        }));
    }

    listCosts(totals, subtotal, total) {
        let costInfo = [];

        var discount = (totals.find(cost => cost.title === 'Discount')); 
        
        costInfo.push({title : "Sub-Total", text : `&euro;${subtotal}`, value : subtotal, class : "ot_subtotal", sort_order : 100});
        costInfo.push({title : "Shipping", text : `&euro;`, value : this.data.shipping, class : "ot_shipping", sort_order : 100});

        if (discount) {
            costInfo.push({title : discount.title, text : `&euro;${discount.price}`, value : discount.price, class : "ot_coupon", sort_order : 200});
        }
        
        costInfo.push({title : "Total", text : `<b>&euro;${total}</b>`, value : total, class : "ot_total", sort_order : 800});

        return costInfo;
    }

    sendOrder(order, apiEndpoint, paymentMethod) {
        // Check to see the validity of the coupon code value
        // If the coupon_code is not equal to invalid it means the coupon_code holds a valid coupon value for the list of products currently inside the cart
        // The value of the coupon_code value will be decided within the setDiscount(code) method above.
        // This method makes the call to Ash api to ensure the coupon_code value is a valid coupon and if so returns the list of approved product_id's the coupon
        // code works against and then I make the discount calculations based on the current products sitting inside the cart. 
        // The setDiscount(code) method above will be called on two ocassions
        // 1: when the user presses apply discount
        // 2: when the user presses proceed to checkout. It need to be called again as the user yes has applied a valid coupon code for the 
        //    products inside his/her shopping cart but when they press proceed to checkout button they may have removed the coupon related products from the shopping cart
        //    so we need to recalculate to ensure the correct discount is been applied based on current products in cart or perhaps remove discount all together
        if (this.data.isDiscount) {
            order['coupon_code'] = this.coupon_code;
        }
        
        return this.http.post(`${apiEndpoint}`, order).pipe(map(response => {

            // Reset the entire cart on successfull post to dotnet api when payment method is account
            // If payment method is stripe we want to reset cart upon successfull post request to stripe after post to holding table api
            // This is because for example if a purchase is made and we make a successfull request to holding table and then stripe request fails
            // we will give user a second chance to press Place order button
            // This will send another request to holding table and then a request over to stripe
            // If stripe fails again then we will clear cart and alert user that there is an issue with our payment provider and please contact support
            
            if (paymentMethod === 'account') {
                this.resetCartItems();
            }

            return response;
        }));
    }

    // Reset cart items upon successfull post to dot net api, after successfull post to stripe, after two failed attempts at posting to stripe
    public resetCartItems(): void {
        this.data.items = [];
        this.data.quantity = 0;
        this.data.subtotal = 0;
        this.data.isDiscount = false;
        this.data.discount = 0;
        this.data.shipping = 0;
        this.data.totals = [];
        this.data.total = 0;

        this.coupon_code = '';
        this.discountTotal = 0;
        this.discountUnitprice = [];
        this.validcoupon = false;
        this.invalidCoupon = false;
        this.couponMsg = 'Invalid Coupon';

        this.save();
        this.calc();
    }

    private calc(): void {
        let quantity = 0;
        let subtotal = 0;

        this.data.items.forEach(item => {
            quantity += item.quantity;
            subtotal += item.product.price * item.quantity;
        });

        const totals: CartTotal[] = [];

        totals.push({
            title: 'Shipping',
            price: this.calcShipping(),
            type: 'shipping'
        });
        // Declan ignoring tax for now. We dont need it in ICS build
        // totals.push({
        //     title: 'Tax',
        //     price: subtotal * 0.20,
        //     type: 'tax'
        // });

        // If discount is true then add it to Cart totals section
        if (this.data.isDiscount == true) {
            totals.push({
                title: 'Discount',
                price: this.data.discount,
                type: 'discount'
            });
        }

        // Always deduct discount from total. If isDiscount == false then discount will always be 0
        // As the total is calculated from the totals array so it can added to the fronent via the for loop to display shipping, tax, discount etc
        // i need to substract this.data.discount * 2 to total will reflect the discount applied
        const total = (subtotal + totals.reduce((acc, eachTotal) => acc + eachTotal.price, 0)) - (this.data.discount * 2);

        this.data.quantity = quantity;
        this.data.subtotal = subtotal;
        this.data.totals = totals;
        this.data.total = total;

        this.itemsSubject$.next(this.data.items);
        this.quantitySubject$.next(this.data.quantity);
        this.subtotalSubject$.next(this.data.subtotal);
        this.totalsSubject$.next(this.data.totals);
        this.totalSubject$.next(this.data.total);
    }

    // Method to calculate shipping based on if any products in the cart are book related products (shipping : 4.50) Basicly a shipping amount greater than 0
    private calcShipping() : number {
        let bookCount = 0;

        this.data.items.forEach(item => {
            if (item.product.customFields.shipping > 0) {
                bookCount += item.quantity;
            }
        });
        return this.shippingCosts(bookCount);
    }

    // Calculate shipping cost based on quantity
    private shippingCosts(quantity) : number {
        let shippingCost = 0;

        // If case in switch contain conditions then value must be true below instead of original paramater that is passed to each case
        switch (true) {
            case (quantity == 1) :
                shippingCost = 4.50;
                break;

            case (quantity >= 2 && quantity <=10) :
                shippingCost = 11;
                break;

            case (quantity >= 11 && quantity <=30) :
                shippingCost = 30;
                break;

            case (quantity >= 31 && quantity <=100) :
                shippingCost = 45;
                break;

            case (quantity >= 101 && quantity <=200) :
                shippingCost = 150;
                break;
        }

        this.data.shipping = shippingCost;

        return this.data.shipping;
    }

    private save(): void {
        localStorage.setItem('cartItems', JSON.stringify(this.data.items));
    }

    private load(): void {
        const items = localStorage.getItem('cartItems');

        if (items) {
            this.data.items = JSON.parse(items);
            //Declan 02/03/2021
            //Check if any products in the cart meet minimum quantity requirements for products
            this.minimumtest.loadMinimumQuantityCount(this.data.items);
        }
    }

    //Declan 01/09/2021
    //There used to be a method here that saved discount related info in localstorage so when the page was refreshed, any discount info from a valid coupon would be reloaded
    //I removed method as I realised, a user could update values in local storage to increase their discount which would of been carried accross to stripe aswell
    //If discount feature is ever re-introduced back into the shop, then we will update the discount code and how it works (Make it better)
    //Discount feature was a quick turn around when shop was been built, it done the job but can be improved

}
