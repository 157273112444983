<div class="site-footer__widget footer-contacts">
    <h5 class="footer-contacts__title">Contact Us</h5>

    <!-- <div class="footer-contacts__text">
        Colm is here to help...
    </div> -->

    <ul class="footer-contacts__contacts">
        <li><i class="footer-contacts__icon fas fa-globe-americas"></i> {{ store.address }}</li>
        <li><i class="footer-contacts__icon far fa-envelope"></i> {{ store.email }}</li>
        <li><i class="footer-contacts__icon fas fa-mobile-alt"></i> {{ store.phone[0] }}</li>
        <li><i class="footer-contacts__icon far fa-clock"></i> {{ store.hours }}</li>
    </ul>
</div>
