<div class="mobilemenu" [ngClass]="{'mobilemenu--open': isOpen}">
    <div class="mobilemenu__backdrop" (click)="mobilemenu.close()"></div>
    <div class="mobilemenu__body">
        <div class="mobilemenu__header">
            <div class="mobilemenu__title">Menu</div>
            <button type="button" class="mobilemenu__close" (click)="mobilemenu.close()">
                <app-icon name="cross-20" size="20"></app-icon>
            </button>
        </div>
        <div class="mobilemenu__content">
            <!-- I am now using observable from logged in out service and not original links from component -->
            <!-- <app-mobile-links [links]="links" (itemClick)="onItemClick($event)"></app-mobile-links> -->
            <app-mobile-links [links]="loggedInOut.mobileMenu$|async" (itemClick)="onItemClick($event)"></app-mobile-links>
        </div>
    </div>
</div>
