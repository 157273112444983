import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class StoreService {
    address = 'ICDL Ireland, Level 1, The Chase, Arkle Road, Sandyford, Dublin 18';
    email = 'support@icdl.ie';
    phone = ['01 23 777 99', '01 23 777 99'];
    hours = 'Mon-Fri 09:00am - 17:00pm';

    get primaryPhone(): string|null {
        return this.phone.length > 0 ? this.phone[0] : null;
    }

    constructor() { }
}
