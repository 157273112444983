<!-- .block-features -->
<div class="block block-features block-features--layout--{{ layout }}">
    <div class="container">
        <div class="block-features__list">
            <div class="block-features__item">
                <div class="block-features__icon">
                    <!-- <app-icon name="fi-free-delivery-48" size="48"></app-icon> -->
                    <i class="fas fa-box-open fa-3x"></i>
                </div>
                <div class="block-features__content">
                    <div class="block-features__title">Free Shipping</div>
                    <div class="block-features__subtitle">For all virtual products</div>
                </div>
            </div>
            <div class="block-features__divider"></div>
            <div class="block-features__item">
                <div class="block-features__icon">
                    <!-- <app-icon name="fi-24-hours-48" size="48"></app-icon> -->
                    <i class="fas fa-envelope fa-3x"></i>
                </div>
                <div class="block-features__content">
                    <div class="block-features__title">Support Email</div>
                    <div class="block-features__subtitle">Email support@icdl.ie</div>
                </div>
            </div>
            <div class="block-features__divider"></div>
            <div class="block-features__item">
                <div class="block-features__icon">
                    <!-- <app-icon name="fi-payment-security-48" size="48"></app-icon> -->
                    <i class="fab fa-cc-stripe fa-3x"></i>
                </div>
                <div class="block-features__content">
                    <div class="block-features__title">100% Safety</div>
                    <div class="block-features__subtitle">Only secure payments</div>
                </div>
            </div>
            <div class="block-features__divider"></div>
            <div class="block-features__item">
                <div class="block-features__icon">
                    <!-- <app-icon name="fi-tag-48" size="48"></app-icon> -->
                    <i class="fas fa-shopping-cart fa-3x"></i>
                </div>
                <div class="block-features__content">
                    <div class="block-features__title">Easy Shopping</div>
                    <div class="block-features__subtitle">For the customer</div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- .block-features / end -->
