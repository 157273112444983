import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { JwtService } from 'src/app/shared/services/jwt.service';

@Component({
  selector: 'app-superuser',
  templateUrl: './superuser.component.html',
  styleUrls: ['./superuser.component.scss']
})
export class SuperuserComponent implements OnInit {

    constructor(
        private router: Router,
        private authenticationService: JwtService
    )
    {
        // if (this.authenticationService.loggedInStatus) {
        //     this.router.navigate(['/']);
        // };
    }

    ngOnInit(): void {
        this.setLocalstorage();
        console.log("Superuser component triggored");
    }

    setLocalstorage(){
        let url = location.href;
        var accessToken = url.split('?')[1];
        console.log('Access Token: ' + accessToken)
        localStorage.setItem('access_token', accessToken);
        this.authenticationService.reloadCustomerdetails();
        this.router.navigate(['/account/dashboard']);
    }
}
