import { Injectable } from '@angular/core';
import { Product } from '../interfaces/product';
import { Category } from '../interfaces/category';
import { Brand } from '../interfaces/brand';

@Injectable({
    providedIn: 'root'
})
export class RootService {
    constructor() { }

    home(): string {
        return '/';
    }

    shop(): string {
        //Declan 16/03/2021 changed `/shop/category` to just / as there are no more public products and /shop/category defaulted to displaying public products
        return '/';
    }

    category(category: Partial<Category>): string {
        if (category.type === 'shop') {
            const basePath = this.shop();

            if ('slug' in category) {
                return `${basePath}/${category.slug}`;
            }
            if ('id' in category) {
                return `${basePath}/${category.id}`;
            }

            throw Error('Provide category with "path", "slug" or "id".');
        }
        if (category.type === 'blog') {
            return this.blog();
        }

        throw Error('Provided category with unknown type.');
    }

    product(product: Partial<Product>): string {
        const basePath = '/shop/products';

        // I want to pull product back by id so commenting out slug
        // if ('slug' in product) {
        //     return `${basePath}/${product.slug}`;
        // }

        // I am now pulling product info back by id and not slug. 
        // Everything is still set up that it says productSluc for route param and in api call in resolver but works fine with id even if id is datatype string. 
        // It's fine like this for now but will change later

        // I am now adding extra param to see wheather product is public or private
        if ('id' in product) {
            return `${basePath}/${product.id}/${product.customFields.isPublic}`;
        }

        throw Error('Provide product with "slug" or "id".');
    }

    brand(brand: Partial<Brand>): string {
        return '/';
    }

    cart(): string {
        return '/shop/cart';
    }

    checkout(): string {
        return '/shop/cart/checkout';
    }

    wishlist(): string {
        return '/shop/wishlist';
    }

    blog(): string {
        return '/blog';
    }

    post(): string {
        return `/blog/post-classic`;
    }

    login(): string {
        return '/account/login';
    }

    terms(): string {
        return '/site/terms';
    }

    notFound(): string {
        return `/site/not-found`;
    }
}
