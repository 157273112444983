import { Component, OnDestroy, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MobileMenuService } from '../../../../shared/services/mobile-menu.service';
import { mobileMenu } from '../../../../../data/mobile-menu';
import { MobileMenuItem } from '../../../../shared/interfaces/mobile-menu-item';
// Display mobile menu items from LoggedInOutService service below
import { LoggedInOutService } from '../../../../shared/services/logged-in-out.service';
import { JwtService } from '../../../../shared/services/jwt.service';

@Component({
    selector: 'app-mobile-menu',
    templateUrl: './mobile-menu.component.html',
    styleUrls: ['./mobile-menu.component.scss']
})
export class MobileMenuComponent implements OnDestroy, OnInit {
    private destroy$: Subject<any> = new Subject();

    isOpen = false;
    // I am no longer using links property below which contains hardcoded mobile menu items
    // I am using observable from logged in out service to pass into input property in in component element for links in view
    links: MobileMenuItem[] = mobileMenu;

    constructor(public mobilemenu: MobileMenuService, public loggedInOut: LoggedInOutService, private jwt: JwtService) { }

    ngOnInit(): void {
        this.mobilemenu.isOpen$.pipe(takeUntil(this.destroy$)).subscribe(isOpen => this.isOpen = isOpen);
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    onItemClick(event: MobileMenuItem): void {
        if (event.type === 'link') {
            this.mobilemenu.close();
        }
        
        // Log user out if logout button is pressed in mobile menu
        if (event.type === 'button' && event.label === 'Logout') {
            this.jwt.logout();
            this.mobilemenu.close();
        }

        // if (event.data && event.data.language) {
        //     console.log(event.data.language); // change language
        // }
    }
}
