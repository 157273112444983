<div class="widget-aboutus widget">
    <h4 class="widget__title">About Blog</h4>
    <div class="widget-aboutus__text">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed tincidunt, erat in malesuada aliquam, est erat faucibus purus,
        eget viverra nulla sem vitae neque. Quisque id sodales libero.
    </div>
    <div class="widget-aboutus__socials">
        <ul>
            <li>
                <a class="widget-aboutus__link widget-aboutus__link--rss" [href]="theme.author.profile_url" target="_blank">
                    <i class="widget-social__icon fas fa-rss"></i>
                </a>
            </li>
            <li>
                <a class="widget-aboutus__link widget-aboutus__link--youtube" [href]="theme.author.profile_url" target="_blank">
                    <i class="widget-aboutus__icon fab fa-youtube"></i>
                </a>
            </li>
            <li>
                <a class="widget-aboutus__link widget-aboutus__link--facebook" [href]="theme.author.profile_url" target="_blank">
                    <i class="widget-aboutus__icon fab fa-facebook-f"></i>
                </a>
            </li>
            <li>
                <a class="widget-aboutus__link widget-aboutus__link--twitter" [href]="theme.author.profile_url" target="_blank">
                    <i class="widget-aboutus__icon fab fa-twitter"></i>
                </a>
            </li>
            <li>
                <a class="widget-aboutus__link widget-aboutus__link--instagram" [href]="theme.author.profile_url" target="_blank">
                    <i class="widget-aboutus__icon fab fa-instagram"></i>
                </a>
            </li>
        </ul>
    </div>
</div>
