<nav class="navbar navbar-dark sticky-top bg-dark flex-md-nowrap p-0">
    <a class="navbar-brand col-sm-12 col-md-12 mr-0" href="/">ICDL Shop 2020</a>
</nav>
<div class="container-fluid">
    <div class="row">
        <main role="main" class="col-md-12 ml-sm-auto col-lg-12 pt-3 px-4">
            <div class="container">
                <div class="jumbotron" style="border-style: solid; border-width: 2px;">
                    <p class="lead" style="text-align: center;"><strong>The ICDL Shop is under maintenance from today until 5pm</strong></p>
                    <p class="lead" style="text-align: center;"><strong>For any queries please email <strong><a href="mailto:support@icdl.ie">support@icdl.ie</a></strong> or call <strong>01 23 777 99</strong></strong></p>
                </div>
            </div>
        </main>
    </div>
</div>