import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtService } from '../../shared/services/jwt.service';

@Component({
    selector: 'app-main',
    templateUrl: './root.component.html',
    styleUrls: ['./root.component.scss']
})
export class RootComponent {
    headerLayout: 'classic'|'compact';

    constructor(
        public router: Router,
        public route: ActivatedRoute,
        private jwt: JwtService
    ) {
        this.route.data.subscribe(data => this.headerLayout = data.headerLayout);
        // Declan reload customer details when page is refreshed. This depends on if valid access token exists or not
        // https://medium.com/front-end-tricks/detect-browser-refresh-with-angular-590cd21d2f02
        // Read article above to only load this method if the browser is refreshed!!!
        this.jwt.reloadCustomerdetails();
        console.log("Root component triggered");
        console.log("CustomerId from root reload");
        console.log(this.jwt.customerId);
    }
}
