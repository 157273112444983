// Declan custom pipe to strip html tags from api response (mainly product description)
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'striphtml'
})

export class StripHtmlTagsPipe implements PipeTransform {
    transform(value: string): any {
        return value.replace(/<img[^>]*>/g,''); // replace img tags
    }
}