import { Component, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'app-pagination',
    templateUrl: './pagination.component.html',
    styleUrls: ['./pagination.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => PaginationComponent),
            multi: true
        }
    ]
})
export class PaginationComponent implements OnChanges, OnInit, ControlValueAccessor  {
    @Input() siblings = 1;
    @Input() current = 1;
    @Input() total = 1;

    @Output() pageChange: EventEmitter<number> = new EventEmitter();

    pages: number[] = [];

    onChange: any = () => {};
    onTouched: any = () => {};

    constructor() { }

    ngOnInit(): void {
        this.calc();
    }

    ngOnChanges(changes: SimpleChanges): void {
        // Declan 28/04/2020 added this.current = 1;
        // This is so I can reset the current value back to 1 so the pagination arrows work and appear as if I reloaded the page
        // This fix was for the pagination on the categories page when a new category was selected from the dropdown which was a change event
        // ngOnChanges also gets triggered when clicking on a link that goes to same component but just a different paramater
        // When clicking on a link /shop/category/3 to /shop/category/5 ngOnInit does not get triggered as I am still on same component.
        // This infact is a change event aswell as I am subscribing to the route change so I can trigger code inside ngOnInit again
        // See PageCategoryIcsComponent component for example
        // As this project is based on a massive template built by another developer I am not sure if this will have any knockon effects
        // Right now the only place where I am using this pagination component is for the categories page and order history page
        // If you find your self here reading this comment it means I broke the code from the place that brought you here. Your're welcome.
        this.current = 1;
        this.calc();
    }

    setPage(value: number, emitEvent: boolean = true): void {
        this.onTouched();

        if (value < 1 || value > this.total || value === this.current) {
            return;
        }

        if (this.current !== value) {
            this.onChange(value);
        }

        this.current = value;
        this.calc();

        if (emitEvent) {
            this.pageChange.emit(this.current);
        }
    }

    trackByFn(index: number): number {
        return index;
    }

    private calc(): void {
        const min = Math.max(1, this.current - this.siblings - Math.max(0, this.siblings - this.total + this.current));
        const max = Math.min(this.total, min + this.siblings * 2);

        this.pages = [];

        for (let i = min; i <= max; i++) {
            this.pages.push(i);
        }
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    writeValue(obj: any): void {
        if (typeof obj === 'number') {
            this.setPage(obj, false);
        }
    }
}
