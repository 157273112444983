import { Component } from '@angular/core';
import { theme } from '../../../data/theme';
import { LoggedInOutService } from '../../shared/services/logged-in-out.service';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
    theme = theme;

    constructor(public loggedInOut: LoggedInOutService) { }
}
