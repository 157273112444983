import { Injectable } from '@angular/core';
import { ShopService } from '../../shared/api/shop.service';
import { Observable, of } from 'rxjs';
import { Product } from '../interfaces/product';
import { NavigationLink } from '../interfaces/navigation-link';
import { MobileMenuItem } from '../interfaces/mobile-menu-item';
import { Link } from '../interfaces/link';

@Injectable({
  providedIn: 'root'
})

// This service is primarly for setting all the values based on wheather a user is logged in or out
// The deffinition of weather a user is logged in or out comes from the jwt service
// This is version 1 of this service, I will come back and refractor code to make more cleaner and maintainable
export class LoggedInOutService {

  frontPageProducts$: Observable<Product[]>;
  footerAccountLinks$: Observable<Link[]>
  // This cannot be an observable as when I populate dropdown with categories|async in the component that prints out categories in drop down I get this error 
  // and I cant resolve. Hence why I am using an array and not an observable 
  // ERROR: ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after it was checked. Previous value: '[Object][Object]' Current value 'null'
  // it's to do with the order the data is loaded in various child component of parent componets etc or the other way around
  // I need to learn to do do this propperly
  // For now treat categories as an array
  categories: NavigationLink[] = [];
  mobileMenu$: Observable<MobileMenuItem[]>;

  public productsTitleHomePage: string;

  constructor(private shop: ShopService) { }

  // https://medium.com/angular-in-depth/angular-show-loading-indicator-when-obs-async-is-not-yet-resolved-9d8e5497dd8
  setLoggedInValues(customerId: number) {
    this.productsTitleHomePage = 'Your Account Products';
    this.frontPageProducts$ = this.shop.getCustomerProducts(customerId);
    this.shop.getICSCategories(customerId).subscribe(result => {
      this.categories = result;
    });

    // Set account links in footer
    const footerAccountLinks: Link[] = [];
    footerAccountLinks.push({label: 'Profile',  url: '/account/dashboard'});
    footerAccountLinks.push({label: 'Order History',  url: '/account/orders'});
    this.footerAccountLinks$ = of(footerAccountLinks);

    // Show menu items for logged in user
    const mobilemenuItems: MobileMenuItem[] = [];

    mobilemenuItems.push({type: 'link', label: 'Categories', url: '', children: this.buildChildMenuItems()});

    mobilemenuItems.push({type: 'link', label: 'Shop', url: '', children: [
      {type: 'link', label: 'Cart', url: '/shop/cart'},
      {type: 'link', label: 'Checkout', url: '/shop/cart/checkout'}
    ]});

    mobilemenuItems.push({type: 'link', label: 'Account', url: '', children: [
      {type: 'link', label: 'Profile',  url: '/account/dashboard'},
      {type: 'link', label: 'Order History',  url: '/account/orders'},
      {type: 'button', label: 'Logout'}
    ]});

    // mobilemenuItems.push({type: 'link', label: 'Site Info', url: '', children: [
    //   {type: 'link', label: 'About Us', url: '/site/about-us'},
    //   {type: 'link', label: 'Contact Us', url: '/site/contact-us'},
    //   {type: 'link', label: 'Terms And Conditions', url: '/site/terms'}
    // ]});
    
    this.mobileMenu$ = of(mobilemenuItems);
  }

  setLoggedOutValues() {
    this.productsTitleHomePage = 'Public Products';
    this.frontPageProducts$ = this.shop.getPublicProducts();

    // Set account links in footer
    const footerAccountLinks: Link[] = [];
    footerAccountLinks.push({label: 'Login',  url: '/account/login'});
    this.footerAccountLinks$ = of(footerAccountLinks);

    // Set categories array to just show Books category item
    this.categories = [{ label: 'Books', url: '/shop/category' }];

    // Show menu items for logged out user
    const mobilemenuItems: MobileMenuItem[] = [];

    // 25/02/2021 when user is logged out dont show categories anymore as there are no more public products in the shop
    // mobilemenuItems.push({type: 'link', label: 'Categories', url: '', children: [
    //   {type: 'link', label: 'Books', url: '/shop/category', children: []},
    // ]});

    mobilemenuItems.push({type: 'link', label: 'Shop', url: '', children: [
      {type: 'link', label: 'Cart', url: '/shop/cart'},
      {type: 'link', label: 'Checkout', url: '/shop/cart/checkout'}
    ]});

    mobilemenuItems.push({type: 'link', label: 'Account', url: '', children: [
      {type: 'link', label: 'Login',  url: '/account/login'}
    ]});

    // mobilemenuItems.push({type: 'link', label: 'Site Info', url: '', children: [
    //   {type: 'link', label: 'About Us', url: '/site/about-us'},
    //   {type: 'link', label: 'Contact Us', url: '/site/contact-us'},
    //   {type: 'link', label: 'Terms And Conditions', url: '/site/terms'}
    // ]});

    this.mobileMenu$ = of(mobilemenuItems);
  }

  // Loop over categories and return an array of categories 
  // that will be rendered where children items for mobile menu items are set when logged in
  buildChildMenuItems() {
    const childmenuitems = [];

    this.categories.forEach(element => {
      childmenuitems.push({type: 'link', label: element.label, url: element.url});
    });
    
    return childmenuitems;
  }
}
