<!-- .block-slideshow -->
<div class="block-slideshow block" [ngClass]="{'block-slideshow--layout--full': !withDepartments, 'block-slideshow--layout--with-departments': withDepartments}">
    <div class="container">
        <div class="row">
            <div *ngIf="withDepartments" class="col-3 d-lg-block d-none" appDepartmentsArea></div>
            <div class="col-12" [ngClass]="{'col-lg-12': !withDepartments, 'col-lg-9': withDepartments}">
                <div class="block-slideshow__body">
                    <!-- Stop using owl caraousel as we did not want/need it. Adust slide image reference below to take first image from array as not using loop now -->
                    <!-- <owl-carousel-o *ngIf="slides?.length" [options]="options" appOwlPreventClick> -->
                        <!-- <ng-template *ngFor="let slide of slides" carouselSlide> -->
                            <a class="block-slideshow__slide" href="" appClick>
                                <div class="block-slideshow__slide-image block-slideshow__slide-image--desktop" [ngStyle]="{'background-image': 'url('+(withDepartments ? slides[0].image_classic : slides[0].image_full)+')'}"></div>
                                <div class="block-slideshow__slide-image block-slideshow__slide-image--mobile" [ngStyle]="{'background-image': 'url('+slides[0].image_mobile+')'}"></div>
                                <div class="block-slideshow__slide-content">
                                    <!-- <div class="block-slideshow__slide-title" [innerHTML]="sanitizer.bypassSecurityTrustHtml(slide.title)"></div> -->
                                    <!-- <div class="block-slideshow__slide-text" [innerHTML]="sanitizer.bypassSecurityTrustHtml(slide.text)"></div> -->
                                    <!-- Button does not show up when not using owl carousel. Need to change css -->
                                    <div class="block-slideshow__slide-button">
                                        <span class="btn btn-primary btn-lg">Shop Now</span>
                                    </div>
                                </div>
                            </a>
                        <!-- </ng-template> -->
                    <!-- </owl-carousel-o> -->
                </div>
            </div>
        </div>
    </div>
</div>
<!-- .block-slideshow / end -->
