<!-- Change withDepartments to false so header image will be now full screen as we dont need col-lg-3 to display categories as categories on home page is now 
collapsable and not fixed open as standard theme had it set -->
<!-- Also hide app-block-slideshow and app-block-features is user logged in and add a div with a condition to add abit of margin -->
<div *ngIf="jwt.loggedInStatus" style="margin-top: 20px;"></div>
<app-block-slideshow *ngIf="!jwt.loggedInStatus" [withDepartments]="false"></app-block-slideshow>

<app-block-features *ngIf="!jwt.loggedInStatus"></app-block-features>

<!-- <app-block-products-carousel
    header="Featured Products"
    layout="grid-4"
    [loading]="featuredProducts.loading"
    [products]="featuredProducts.products"
    [groups]="featuredProducts.groups"
    (groupChange)="groupChange(featuredProducts, $event)"
></app-block-products-carousel> -->

<!-- <app-block-banner></app-block-banner> -->

<!-- 25/02/2021 We no longer need public products. All products are now private, only for logged in customers
Code base is still set up to handle both public/private products dependant if user is logged in or not
ngif added below to check if user is logged in. If so show products and if not no public products will be shown
Remove ngif below to go back to public/private products working as it normaily did -->
<app-block-products *ngIf="jwt.loggedInStatus" [header]="loggedInOut.productsTitleHomePage" layout="large-first" [products]="loggedInOut.frontPageProducts$|async"></app-block-products>

<!-- <app-block-categories header="Popular Categories" layout="classic" [categories]="popularCategories$|async"></app-block-categories> -->

<!-- <app-block-products-carousel
    header="New Arrivals"
    layout="horizontal"
    [rows]="2"
    [loading]="latestProducts.loading"
    [products]="latestProducts.products"
    [groups]="latestProducts.groups"
    (groupChange)="groupChange(latestProducts, $event)"
></app-block-products-carousel> -->

<!-- <app-block-posts header="Latest News" layout="list-sm" [posts]="posts"></app-block-posts> -->

<!-- <app-block-brands [brands]="brands$|async"></app-block-brands> -->

<!-- <app-block-product-columns>
    <app-block-product-columns-item header="Top Rated Products" [products]="columnTopRated$|async"></app-block-product-columns-item>
    <app-block-product-columns-item header="Special Offers" [products]="columnSpecialOffers$|async"></app-block-product-columns-item>
    <app-block-product-columns-item header="estsellers" [products]="columnBestsellers$|async"></app-block-product-columns-item>
</app-block-product-columns> -->
